import React from 'react';

export default function CommingSoon({ className }) {
  return (
    <div>
      <span
        className={`text-red-500 text-[8px] absolute -top-2.5 left-auto right-auto mx-auto text-center w-full ${className}`}>
        Coming Soon !
      </span>
    </div>
  );
}
