import React, { useState } from 'react';

import BlueRightArrow from '../assets/img/icons/blue_right_arrow.svg';
import { MainTabs_Data, Tabs_Data_Mobile } from '../_mock/dummyData';
// import TableArrow from "../../assets/img/icons/table_arrow.svg";

export default function MobileTabs() {
  const [showValues, setShowValues] = useState(false);
  const [viewMore, setViewMore] = useState(3);
  console.log({ viewMore });

  return (
    <div>
      <div className='block md:hidden' data-aos='fade-up' data-aos-duration='1300'>
        <div>
          {MainTabs_Data.map((item, idx) => {
            if (idx < viewMore) {
              return (
                <div>
                  <div
                    key={idx}
                    className='flex items-center my-10 text-white justify-between w-full pt-10 pb-[30px] px-6 bg-[rgba(255,255,255,0.05)] border-[1px] border-themeColor '>
                    <div className='text-start text-[16px] font-satoshi font-[700] uppercase flex flex-col gap-[20px] '>
                      <p>draws</p>
                      <p>date</p>
                      <p>rewards</p>
                      <p>address</p>
                      <p>user odds</p>
                      <p>user win history</p>
                      <p>user time in app</p>
                    </div>
                    <div className='text-[14px] text-end uppercase font-satoshi flex flex-col gap-[20px] '>
                      <p>{item.title1}</p>
                      <p>{item.title2}</p>
                      <p>{item.title3}</p>
                      <p>{item.title4}</p>
                      <p>{item.title5}</p>
                      <p>{item.title6}</p>
                      <p>{item.title7}</p>
                    </div>
                  </div>

                  <div
                    onClick={() => (idx === showValues ? setShowValues(null) : setShowValues(idx))}
                    className='cursor-pointer text-right'>
                    {/* <!-- --link-btn--  --> */}
                    <button className='pr-3 inline-flex gap-3 items-center text-[14px] text-themeColor font-inter font-[400] transition hover:text-white'>
                      See All
                      <img src={BlueRightArrow} alt='' />
                    </button>
                    {idx === showValues &&
                      Tabs_Data_Mobile.map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            className='flex items-center my-[20px] justify-between w-full bg-[#282B46] p-6 mt-10 text-white '>
                            <div className='text-start text-[16px] font-satoshi font-[700] uppercase flex flex-col gap-[20px] '>
                              <p>winner</p>
                              <p>DATE</p>
                              <p>REWARDS</p>
                              <p>ADDRESS</p>
                              <p>USER ODDS</p>
                              <p>USER WIN HISTORY</p>
                              <p>USER TIME IN APP</p>
                            </div>
                            <div className='text-[14px]  font-satoshi flex flex-col gap-[20px] '>
                              <p>{item.draw}</p>
                              <p>{item.date}</p>
                              <p>{item.reward}</p>
                              <p>{item.address}</p>
                              <p>{item.userOdds}</p>
                              <p>{item.userWinHistory}</p>
                              <p>{item.userTime}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            }
          })}
        </div>
        {viewMore !== 6 ? (
          <div className='w-full text-center my-20 lg:block'>
            <button
              onClick={() => setViewMore(6)}
              className='w-[250px]  border border-[#15BFFD] text-[#A1E4DF] text-[17px] px-[11px] py-[8px] rounded-2xl  font-clash '>
              View More
            </button>
          </div>
        ) : (
          <div className='w-full text-center my-20 lg:block'>
            <button
              onClick={() => setViewMore(3)}
              className='w-[250px]  border border-[#15BFFD] text-[#A1E4DF] text-[17px] px-[11px] py-[8px] rounded-2xl  font-clash '>
              View Less
            </button>
          </div>
        )}
        {/* <!-- --single-item--  --> */}
      </div>
    </div>
  );
}
