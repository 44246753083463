import React, { useState } from 'react';
import GetStartedToday from '../../assets/img/bg/Get_Started_bg.png';
import LinkButtonRightArrow from '../../assets/img/icons/link_btn_right_arrow.svg';
import GetStartThum from '../../assets/img/thumbs/get_start_thum.png';

export default function GetStarted() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = () => {
    if (name !== '' && email !== '') {
      console.log('Submit', name, email);
      setIsSubmit(true);
    }
  };

  return (
    <section className='py-[50px] md:py-[110px]'>
      <div className='container'>
        <div
          data-aos='fade-up'
          data-aos-duration='1300'
          className='flex flex-wrap gap-[31px] py-[50px] sm:py-[100px] px-[22px] sm:px-[75px] rounded-[16px] h-full lg:h-[528px] max-w-[1233px] bg-cover mx-auto'
          style={{
            backgroundColor: 'yellow',
            backgroundImage: `url(${GetStartedToday})`,
          }}>
          <div className='w-[100%] lg:w-[55%] order-2 lg:order-1'>
            {/* <p className='my-[43px] text-center sm:text-left text-[20px] sm:text-[24px] text-[#FFFFFF] font-[500] font-inter leading-[32px]'>
              the first meme coin to harness the power of Liquidity Staking Derivative Protocol. Our project merges the
              playful nature of meme coins with the intelligent strategies of smart investors.
            </p> */}

            {isSubmit ? (
              <p
                className='my-[43px] text-center sm:text-left text-md sm:text-2xl text-[#15Bffd] font-bold font-inter  '
                style={{
                  letterSpacing: '0.05em',
                  wordSpacing: '0.08em',
                }}>
                Thank You For Joining The Waitlist, Stay Tuned For Updates On The Rewards, TestNet, dApp Release, And
                More
              </p>
            ) : (
              <div className='max-w-[415px] mx-auto sm:mx-0 '>
                <h2 className='text-center sm:text-left text-[32px] sm:text-[35px] text-[#fff] font-[600] font-clash'>
                  Get Started Today!
                </h2>
                <p className='my-[23px]  text-center sm:text-left text-[20px] sm:text-[24px] text-[#FFFFFF] font-[500] font-inter leading-[32px]'>
                  Secure Your Spot: Join the Brainz Waitlist for Exclusive Updates and Early Access!
                </p>

                <input
                  type='text'
                  id='name'
                  onChange={(e) => setName(e.target.value)}
                  className='bg-gray-50 border border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  outline-none '
                  placeholder='Name'
                  required
                />
                <input
                  type='email'
                  id='email'
                  onChange={(e) => setEmail(e.target.value)}
                  className='bg-gray-50 border mt-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  outline-none '
                  placeholder='Email'
                  required
                />
                <div className='text-center sm:text-left'>
                  <button
                    onClick={handleSubmit}
                    // href='staking.html'
                    className='text-[15px] capitalize mt-4 md:text-[20px] text-[#000] leading-none bg-themeColor py-[12px] px-[15px] sm:px-[24px] md:py-[15px] md:px-[35px] hover:text-themeColor font-inter font-[500] inline-block border-[1px] border-themeColor rounded-[30px] hover:border-[1px] hover:border-themeColor hover:rounded-[30px] transition hover:bg-transparent'>
                    Join the waitlist
                  </button>
                </div>
              </div>
            )}

            {/* <div className='flex gap-7 sm:gap-10 justify-center sm:justify-start'>
              <a
                href='staking.html'
                className='text-[15px] md:text-[20px] text-[#000] leading-none bg-themeColor py-[12px] px-[15px] sm:px-[24px] md:py-[15px] md:px-[35px] hover:text-themeColor font-inter font-[500] inline-block border-[1px] border-themeColor rounded-[30px] hover:border-[1px] hover:border-themeColor hover:rounded-[30px] transition hover:bg-transparent'>
                Launch dApp
              </a>

              <a
                href='https://brainzfinance.gitbook.io/whitepaper/'
                className='flex gap-2 sm:gap-3 items-center text-[15px] md:text-[20px] leading-none py-[12px] md:py-[15px] text-themeColor font-inter font-[500] transition hover:text-white'>
                Learn more
                <img src={LinkButtonRightArrow} alt='' />
              </a>
            </div> */}
          </div>
          <div className='w-[210px] mx-auto opacity-70 md:opacity-100 lg:w-[39%] mb-5 lg:mb-0 order-1 lg:order-2'>
            <img className='mx-auto' src={GetStartThum} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
}
