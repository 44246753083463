import React from 'react';

import SmallBlueStar from '../../assets/img/icons/small_blue_star.svg';

import WorkRightShape from '../../assets/img/bg/work_right_shape.png';
import WorkLeftShape from '../../assets/img/bg/work_left_shape.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
import StakingCalculation from '../../Components/staking/Calculation';
import Accordian from '../../Components/staking/Accordian';
import MobileTabs from '../../Components/MobileTabs';
const Staking = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);
  const [activeTab, setActiveTab] = React.useState('tab1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <main className='main overflow-hidden'>
        {/* <!--========  rolling banner start =========--> */}
        <section>
          <div className='mt-[125px] md:mt-[210px] lg:mt-[180px]'>
            <div className='container bg-red-500 text-white py-3 xl:py-4 xl:rounded-xl'>
              <marquee behavior="scroll" direction="left">
                <span className='xl:text-xl tracking-wide'>“We're currently on testnet but stay tuned because $Brainz is launching soon.”</span>
              </marquee>
            </div>
          </div>
        </section>
        {/* <!--========  rolling banner end =========--> */}

        {/* <!--========  REWARDS  section Start =========--> */}
        <StakingCalculation />
        {/* <!--========  REWARDS  section End =========--> */}

        {/* <!--========  Table  section start =========--> */}
        <section>
          <div className='container'>
            <div className='mt-6 lg:mt-[29px]'>
              {/* <!-- --large-screen-table--  --> */}
              <div className='hidden md:block' data-aos='fade-up' data-aos-duration='1300'>
                <Accordian />
              </div>
              {/* <!-- --small-screen-table--  --> */}
              <MobileTabs />
            </div>
          </div>
        </section>
        {/* <!--========  Table  section End =========--> */}

        {/* <!--========  How  It  Works  section start =========--> */}
        <section className='relative -z-10 pt-[20px] md:pt-[50px]'>
          {/* <!-- --shapes--  --> */}

          <img className='hidden md:block absolute top-[-10%] left-0 -z-10' src={WorkLeftShape} alt='' />
          <img className='hidden md:block absolute top-[-10%] right-0 -z-10' src={WorkRightShape} alt='' />

          {/* <!-- ---stars--  --> */}

          <div className='hidden md:block'>
            <img className='absolute top-[5%] left-[35%] -z-10' src={SmallBlueStar} alt='' />
            <img className='absolute top-[6%] left-[70%] -z-10' src={SmallBlueStar} alt='' />

            <img className='absolute -z-10 top-[18%] left-[75%]' src={SmallBlueStar} alt='' />
            <img className='absolute -z-10 left-[5%] top-[18%]' src={SmallBlueStar} alt='' />
          </div>

          <div className='container'>
            {/* <!-- --heading--  --> */}
            <h4
              className='my-[40px] sm:my-[80px] md:my-[112px] text-center text-[32px] sm:text-[45px] text-white font-[600] font-clash'
              data-aos='fade-in'
              data-aos-duration='1300'>
              How It Works
            </h4>
            <div
              className='relative md:after:absolute md:after:top-0 md:after:left-[37px] md:after:bg-themeColor md:after:w-[3px] after:h-[85%] after:-z-10'
              data-aos='fade-up'
              data-aos-duration='1300'>
              {/* <!-- --single-item--  --> */}
              <div className='min-h-[346px] mb-[60px] md:mb-[43px] flex-col md:flex-row flex gap-[52px]'>
                {/* <!-- number  --> */}
                <div className='mx-auto md:mx-0 shrink-0 sm:w-[75px] sm:h-[75px] w-[65px] h-[65px] rounded-full bg-themeColor flex justify-center items-center text-[40px] text-white font-[700] font-clash'>
                  1
                </div>
                {/* <!-- --content-- --> */}
                <div className='' data-aos='fade-up' data-aos-duration='1300'>
                  <h4 className='text-center md:text-left mb-[36px] text-[24px] md:text-[32px] text-white font-[600] font-clash'>
                    Ignite Your Investment Journey
                  </h4>
                  <p className='text-center md:text-left max-w-[1112px] text-[20px] md:text-[24px] text-[rgba(255,255,255,0.8)] font-[400] leading-[43px] tracking-[0.48px] font-satoshi'>
                    Prepare for an exhilarating ride with the Brainz Finance Protocol! Begin by depositing your stETH
                    tokens and witness the magic unfold. Our smart contract swiftly mints PST (Pool Share Token) in a
                    1:1 ratio, reflecting your ownership in the protocol. These tokens are crucial for future
                    withdrawals. And remember, the minimum deposit amount is 0.1 stETH, though we welcome fractions
                    too—embrace flexibility!
                  </p>
                </div>
              </div>
              {/* <!-- --single-item--  --> */}
              <div className='min-h-[346px] mb-[60px] md:mb-[43px] flex-col md:flex-row flex gap-[52px]'>
                {/* <!-- number  --> */}
                <div className='mx-auto md:mx-0 shrink-0 sm:w-[75px] sm:h-[75px] w-[65px] h-[65px] rounded-full bg-themeColor flex justify-center items-center text-[40px] text-white font-[700] font-clash'>
                  2
                </div>
                {/* <!-- --content-- --> */}
                <div className='' data-aos='fade-up' data-aos-duration='1300'>
                  <h4 className='text-center md:text-left mb-[36px] text-[24px] md:text-[32px] text-white font-[600] font-clash'>
                    Watch Your Rewards Blossom
                  </h4>
                  <p className='text-center md:text-left max-w-[1112px] text-[20px] md:text-[24px] text-[rgba(255,255,255,0.8)] font-[400] leading-[43px] tracking-[0.48px] font-satoshi'>
                    Sit back and let your ETH staking rewards flourish within the protocol. As time ticks by, your stETH
                    balance grows at the current Lido APR speed, updating every 24 hours. Feel the excitement as your
                    assets accumulate and your potential expands.
                  </p>
                </div>
              </div>
              {/* <!-- --single-item--  --> */}
              <div className='min-h-[346px] mb-[60px] md:mb-[43px] flex-col md:flex-row flex gap-[52px]'>
                {/* <!-- number  --> */}
                <div className='mx-auto md:mx-0 shrink-0 sm:w-[75px] sm:h-[75px] w-[65px] h-[65px] rounded-full bg-themeColor flex justify-center items-center text-[40px] text-white font-[700] font-clash'>
                  3
                </div>
                {/* <!-- --content-- --> */}
                <div className='' data-aos='fade-up' data-aos-duration='1300'>
                  <h4 className='text-center md:text-left mb-[36px] text-[24px] md:text-[32px] text-white font-[600] font-clash'>
                    Unleash the Power of Yield
                  </h4>
                  <p className='text-center md:text-left max-w-[1112px] text-[20px] md:text-[24px] text-[rgba(255,255,255,0.8)] font-[400] leading-[43px] tracking-[0.48px] font-satoshi'>
                    Prepare for the grand reveal! Every 604,800 seconds, a draw occurs to distribute the yield among our
                    esteemed users. Thanks to Chainlink VRF, a renowned solution for impeccable randomness, winners are
                    chosen with utmost fairness. Your odds of victory dynamically change in real time, adding an extra
                    thrill to the game. No need to re-enter manually for subsequent draws; your initial deposits remain
                    in the protocol, fueling your future chances.
                  </p>
                </div>
              </div>
              {/* <!-- --single-item--  --> */}
              <div className='min-h-[346px] mb-[60px] md:mb-[43px] flex-col md:flex-row flex gap-[52px]'>
                {/* <!-- number  --> */}
                <div className='mx-auto md:mx-0 shrink-0 sm:w-[75px] sm:h-[75px] w-[65px] h-[65px] rounded-full bg-themeColor flex justify-center items-center text-[40px] text-white font-[700] font-clash'>
                  4
                </div>
                {/* <!-- --content-- --> */}
                <div className='' data-aos='fade-up' data-aos-duration='1300'>
                  <h4 className='text-center md:text-left mb-[36px] text-[24px] md:text-[32px] text-white font-[600] font-clash'>
                    Victory's Sweet Rewards
                  </h4>
                  <p className='text-center md:text-left max-w-[1112px] text-[20px] md:text-[24px] text-[rgba(255,255,255,0.8)] font-[400] leading-[43px] tracking-[0.48px] font-satoshi'>
                    When fortune favors you, success is celebrated instantly. As a winner, you automatically receive
                    your rewards in the form of PST—equivalent to your stETH holdings—bolstering your balance and
                    amplifying your odds for future draws. Sit back and let the protocol handle the rest; there's no
                    need to manually claim rewards. Just remember, a 10% winning fee applies—small price for a taste of
                    triumph.
                  </p>
                </div>
              </div>
              {/* <!-- --single-item--  --> */}
              <div className='min-h-[346px] flex-col md:flex-row flex gap-[52px]'>
                {/* <!-- number  --> */}
                <div className='mx-auto md:mx-0 shrink-0 sm:w-[75px] sm:h-[75px] w-[65px] h-[65px] rounded-full bg-themeColor flex justify-center items-center text-[40px] text-white font-[700] font-clash'>
                  5
                </div>
                {/* <!-- --content-- --> */}
                <div className='' data-aos='fade-up' data-aos-duration='1300'>
                  <h4 className='text-center md:text-left mb-[36px] text-[24px] md:text-[32px] text-white font-[600] font-clash'>
                    Freedom to Withdraw
                  </h4>
                  <p className='text-center md:text-left max-w-[1112px] text-[20px] md:text-[24px] text-[rgba(255,255,255,0.8)] font-[400] leading-[43px] tracking-[0.48px] font-satoshi'>
                    Maintaining control of your funds is our priority. At any time, you have the power to withdraw your
                    initial deposit. Simply follow the withdrawal process, and our protocol will burn your PST tokens,
                    exchanging them for stETH at a 1:1 ratio. Your financial freedom is just a click away.
                  </p>
                </div>
              </div>
            </div>

            <div className='hidden md:block ml-[120px]' data-aos='fade-up' data-aos-duration='1300'>
              <a
                href='https://brainzfinance.gitbook.io/whitepaper/'
                className='w-[222px] text-center text-[20px] py-[13px] px-[25px] text-themeColor font-inter font-[500] inline-flex items-center justify-center group border-[1px] border-themeColor rounded-[30px] transition hover:bg-white hover:text-#15BFFD'>
                <span className='inline-block mr-3 lg:mr-3 xl:mr-4'>
                  <svg
                    className='h-4 md:h-5 lg:h-[22px]'
                    viewBox='0 0 25 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      className='group-hover:fill-#15BFFD'
                      d='M23 4H3C2.73478 4 2.48043 3.89464 2.29289 3.70711C2.10536 3.51957 2 3.26522 2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H20C20.2652 2 20.5196 1.89464 20.7071 1.70711C20.8946 1.51957 21 1.26522 21 1C21 0.734784 20.8946 0.48043 20.7071 0.292893C20.5196 0.105357 20.2652 0 20 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V19C0 19.7956 0.316071 20.5587 0.87868 21.1213C1.44129 21.6839 2.20435 22 3 22H23C23.5304 22 24.0391 21.7893 24.4142 21.4142C24.7893 21.0391 25 20.5304 25 20V6C25 5.46957 24.7893 4.96086 24.4142 4.58579C24.0391 4.21071 23.5304 4 23 4ZM23 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V5.82875C2.32109 5.94257 2.65933 6.0005 3 6H23V20ZM17 12.5C17 12.2033 17.088 11.9133 17.2528 11.6666C17.4176 11.42 17.6519 11.2277 17.926 11.1142C18.2001 11.0007 18.5017 10.9709 18.7926 11.0288C19.0836 11.0867 19.3509 11.2296 19.5607 11.4393C19.7704 11.6491 19.9133 11.9164 19.9712 12.2074C20.0291 12.4983 19.9993 12.7999 19.8858 13.074C19.7723 13.3481 19.58 13.5824 19.3334 13.7472C19.0867 13.912 18.7967 14 18.5 14C18.1022 14 17.7206 13.842 17.4393 13.5607C17.158 13.2794 17 12.8978 17 12.5Z'
                      fill='#15BFFD'
                    />
                  </svg>
                </span>{' '}
                Learn more
              </a>
            </div>
          </div>
        </section>
        {/* <!--========  How  It  Works  section End =========--> */}

        {/* <!--======== opportunity  section Start =========--> */}
        <section className='mt-[50px] md:mt-[100px] py-[50px]' data-aos='fade-up' data-aos-duration='1300'>
          <div className='container'>
            {/* <!-- opportunity-inner-  --> */}
            <div className="py-[50px] md:py-[204px] rounded-[10px] bg-[url('assets/img/bg/opportunity_bg.png')] bg-cover bg-center px-3">
              <p className='max-w-[1168px] tracking-[0.66px] mx-auto text-center text-[20px] md:text-[33px] text-[#fff] font-[400] font-satoshi leading-[55px]'>
                Experience the Brainz Finance Protocol, where your journey is in your hands—empowering, exhilarating,
                and completely non-custodial.
              </p>
            </div>
          </div>
        </section>
        {/* <!--======== opportunity  section End =========--> */}
      </main>
    </>
  );
};

export default Staking;
