import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { configureChains, createConfig } from 'wagmi'
import { mainnet, sepolia, goerli, polygon, polygonMumbai } from 'wagmi/chains'

export const chainId = 5;
export const DEAD = '0x000000000000000000000000000000000000dead';
export const ZERO = '0x0000000000000000000000000000000000000000';


const chains = [sepolia, goerli, polygonMumbai]
export const projectId = '7392393195e7d432a8c841b79f3c2616'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ version: 1,projectId, chains }),
  publicClient
})

export const ethereumClient = new EthereumClient(wagmiConfig, chains)


