export const Tabs_Data = [
  {
    id: 10,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },
  {
    id: 9,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },
  {
    id: 8,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },

  {
    id: 7,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },
  {
    id: 6,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },

  {
    id: 5,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },

  {
    id: 4,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },
  {
    id: 3,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },

  {
    id: 2,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },
  {
    id: 1,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '7.97 stETH',
    address: 'MULTIPLE',
    odds: 'MULTIPLE',
    history: '0',
    time: 'MULTIPLE',
  },
];

export const OpenedTabs_Data = [
  {
    id: 1,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '3.21 stETH',
    address: '0X0FD7...CAF4',
    odds: '1 : 138',
    history: '0',
    time: '7 DAYS',
  },
  {
    id: 2,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '1.93 stETH',
    address: '0XD922...5E99',
    odds: '1 : 6.23',
    history: '0',
    time: '76 DAYS',
  },
  {
    id: 3,
    imgUrl: '/assets/grayarrow.png',
    date: 'JUN 16, 2023',
    rewards: '1.28 stETH',
    address: '0X43E1...5FE5',
    odds: '1 : 16.22',
    history: '0',
    time: '95 DAYS',
  },
];

// show on click
export const Tabs_Data_Mobile = [
  {
    id: 1,
    draw: '1',
    date: 'JUN 16, 2023 ',
    reward: '7.97 stETH',
    address: '0X0FD7...CAF4',
    userOdds: '1 : 138',
    userWinHistory: '0',
    userTime: '7 DAYS',
  },
  {
    id: 2,
    draw: '2',
    date: 'JUN 16, 2023 ',
    reward: '7.97 stETH',
    address: '0XD922...5E99',
    userOdds: '1 : 6.23',
    userWinHistory: '0',
    userTime: '76 DAYS',
  },
  {
    id: 3,
    draw: '3',
    date: 'JUN 16, 2023 ',
    reward: '7.97 stETH',
    address: '0X43E1...5FE5',
    userOdds: '1 : 16.22',
    userWinHistory: '0',
    userTime: '95 DAYS',
  },
];

// defaut tabs
export const MainTabs_Data = [
  {
    id: 1,
    title1: '#3',
    title2: 'JUN 16, 2023 ',
    title3: '7.97 stETH',
    title4: 'multiple',
    title5: 'multiple',
    title6: '0',
    title7: 'multiple',
  },
  {
    id: 2,

    title1: '#2',
    title2: 'JUN 16, 2023 ',
    title3: '7.97 stETH',
    title4: 'multiple',
    title5: 'multiple',
    title6: '0',
    title7: 'multiple',
  },
  {
    id: 3,
    title1: '#1',
    title2: 'JUN 16, 2023 ',
    title3: '7.97 stETH',
    title4: 'multiple',
    title5: 'multiple',
    title6: '0',
    title7: 'multiple',
  },
  {
    id: 2,

    title1: '#2',
    title2: 'JUN 16, 2023 ',
    title3: '7.97 stETH',
    title4: 'multiple',
    title5: 'multiple',
    title6: '0',
    title7: 'multiple',
  },
  {
    id: 3,
    title1: '#1',
    title2: 'JUN 16, 2023 ',
    title3: '7.97 stETH',
    title4: 'multiple',
    title5: 'multiple',
    title6: '0',
    title7: 'multiple',
  },
];
