import React, { useState } from 'react';
import Star7 from '../../assets/img/icons/Star 7.svg';
import { ethers } from 'ethers';
import StakePrizePool from "../../Contracts/StakingContract/StakePrizePool.json";
import PoolShare from "../../Contracts/TIcket/Ticket.json"
import PoolShareAddress from "../../Contracts/TIcket/TIcketAddress.json"
import StakingPrizePoolAddress from "../../Contracts/StakingContract/StakePrizePoolAddress.json";
import { useEffect } from 'react';

export default function MyBalance() {

  const [claimableReward, setClaimableReward] = useState(0.0)
  const [totalDeposit, setTotalDeposit] = useState(0);


  const getTotalDeposited = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const poolShare = new ethers.Contract(PoolShareAddress.address, PoolShare, Signer);
      const supply = await poolShare.totalSupply();
      setTotalDeposit(ethers.utils.formatEther(supply));
    } catch (error) {
      console.log(error);
    }
  };


  const getClaimableReward = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const stakePrizePool = new ethers.Contract(
        StakingPrizePoolAddress.address,
        StakePrizePool,
        Signer
      );
      const balance = await stakePrizePool.getClaimableReward(address)
      const formattedBalance = ethers.utils.formatUnits(balance, 18); // Assuming 18 decimals, adjust if necessary
      const roundedBalance = parseFloat(formattedBalance).toFixed(2);
      const stakeInfo = await stakePrizePool.getUserStakeInfo(address)
      console.log("stake info", stakeInfo)

      setClaimableReward(roundedBalance);

    } catch (error) {
      console.log(error)
    }
  };
  const claimToken = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const stakePrizePool = new ethers.Contract(
        StakingPrizePoolAddress.address,
        StakePrizePool,
        Signer
      );
      await stakePrizePool.claim(address)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getTotalDeposited()
    getClaimableReward()
  }, []);
  return (
    <div>
      <div className='calculation_Section w-full max-w-[342px] mx-auto md:max-w-full h-full lg:py-5'>
        {/* <!-- top-part  --> */}
        <div className='pt-[34px] px-[32px] pb-[42px]'>
          <h6 className=' text-[17px] text-center lg:text-left text-blue-100 font-[500] font-clash'>DRAW #0</h6>
          <div className='grid place-items-center mt-[53px] lg:pl-6 md:grid-cols-4 '>
            <div className='py-[40px] bg-[#ffffff0a] w-full  px-[45px] col-span-1 md:col-span-3  shadow-[0_0px_36px_0px_rgba(21,191,253,0.47)] border border-themeColor rounded-[16px]'>
              <h6 className='hidden lg:block mb-[37px] text-[24px] text-center text-blue-100 font-[500] font-clash'>
                THIS WEEK'S REWARDS
              </h6>

              <h6 className='block lg:hidden mb-[37px] text-[24px] text-center text-blue-100 font-[500] font-clash'>
                REWARDS
              </h6>

              <div className='flex gap-[90px] md:gap-0 flex-col md:flex-row'>
                {/* <!-- single-item  --> */}
                <div className='w-full md:w-[33%] font-satoshi flex flex-col justify-center items-center text-center'>
                  <div className='flex items-center gap-2'>
                    <div className=''>
                      <img src={Star7} alt='' />
                    </div>
                    <p className='text-[25px] text-center text-blue-100 font-[700]'>0.00</p>
                  </div>
                  <span className='mt-2 text-[14px] text-center text-[rgba(255,255,255,0.5)] font-[500]'>stETH</span>
                </div>
                {/* <!-- single-item  --> */}
                <div className='w-full md:w-[33%] font-satoshi flex flex-col justify-center items-center text-center'>
                  <div className='flex items-center gap-2'>
                    <div className=''>
                      <img src={Star7} alt='' />
                    </div>
                    <p className='text-[25px] text-center text-blue-100 font-[700]'>0.00</p>
                  </div>
                  <span className='mt-2 text-[14px] text-center text-[rgba(255,255,255,0.5)] font-[500] '>stETH</span>
                </div>
                {/* <!-- single-item  --> */}
                <div className='w-full md:w-[33%] font-satoshi flex flex-col justify-center items-center text-center'>
                  <div className='flex items-center gap-2'>
                    <div className=''>
                      <img src={Star7} alt='' />
                    </div>
                    <p className='text-[25px] text-center text-blue-100 font-[700]'>0.00</p>
                  </div>
                  <span className='mt-2 text-[14px] text-center text-[rgba(255,255,255,0.5)] font-[500]'>stETH</span>
                </div>
              </div>
            </div>
            <div className='flex justify-center items-center w-full'>
              <div className='text-white mt-[60px] md:mt-0  '>
                <p className='text-[13px] text-center text-[#ffffff5e]'>ENDS IN</p>
                <p className='mt-1 text-[20px]'>0d 0h</p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- --divider--  --> */}
        <div className='h-[1px] w-full bg-[rgba(255,255,255,0.20)]'></div>

        {/* <!-- --bottom-part--  --> */}
        <div className='pt-[34px] flex items-start gap-[65px] justify-center '>
          <div className='w-[268px] hidden md:pr-[65px] border-[rgba(255,255,255,0.20)] md:border-r md:block '>
            <div className=''>
              <h6 className='text-[17px] text-center text-blue-100 font-[500] font-clash'>Brainz Rewards</h6>
              <h2 className='mt-[35px] text-[29px] text-center text-white font-[400] font-satoshi'>{claimableReward}</h2>

              <span className='text-[12px] mt-[23px] text-center block text-white font-[400] font-satoshi'>
                0% Apy
              </span>
            </div>

            <div className='mb-[20px] mt-[23px] md:mb-[0] w-[209px] mx-auto text-center' onClick={claimToken}>
              <a
                href='#'
                className='text-center text-[14px] py-[14px] px-[20px] text-themeColor font-inter font-[500] inline-flex justify-center items-center border-[1px] border-themeColor rounded-[30px] transition hover:bg-themeColor hover:text-[#000] group'>
                <span className='inline-block mr-3 lg:mr-3 xl:mr-4'>
                  <svg
                    className='h-4 md:h-5 lg:h-[22px]'
                    viewBox='0 0 25 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      className='group-hover:fill-black'
                      d='M23 4H3C2.73478 4 2.48043 3.89464 2.29289 3.70711C2.10536 3.51957 2 3.26522 2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H20C20.2652 2 20.5196 1.89464 20.7071 1.70711C20.8946 1.51957 21 1.26522 21 1C21 0.734784 20.8946 0.48043 20.7071 0.292893C20.5196 0.105357 20.2652 0 20 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V19C0 19.7956 0.316071 20.5587 0.87868 21.1213C1.44129 21.6839 2.20435 22 3 22H23C23.5304 22 24.0391 21.7893 24.4142 21.4142C24.7893 21.0391 25 20.5304 25 20V6C25 5.46957 24.7893 4.96086 24.4142 4.58579C24.0391 4.21071 23.5304 4 23 4ZM23 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V5.82875C2.32109 5.94257 2.65933 6.0005 3 6H23V20ZM17 12.5C17 12.2033 17.088 11.9133 17.2528 11.6666C17.4176 11.42 17.6519 11.2277 17.926 11.1142C18.2001 11.0007 18.5017 10.9709 18.7926 11.0288C19.0836 11.0867 19.3509 11.2296 19.5607 11.4393C19.7704 11.6491 19.9133 11.9164 19.9712 12.2074C20.0291 12.4983 19.9993 12.7999 19.8858 13.074C19.7723 13.3481 19.58 13.5824 19.3334 13.7472C19.0867 13.912 18.7967 14 18.5 14C18.1022 14 17.7206 13.842 17.4393 13.5607C17.158 13.2794 17 12.8978 17 12.5Z'
                      fill='#15BFFD'
                    />
                  </svg>
                </span>{' '}
                CLAIM TOKENS
              </a>
            </div>
          </div>
          <div className='text-center flex flex-col items-center justify-center w-[278px]'>
            <p className='uppercase font-clash text-[17px] text-blue-100'>Total Deposit</p>
            <div className='flex justify-center w-[278px] pb-[31px] my-[36px] pt-[25px] rounded-[12px] bg-gradient items-end gap-[7px]'>
              <p className='text-[24px] text-blue-200 '>{totalDeposit}</p>
              <p className='text-[11px] text-white font-satoshi '>stETH</p>
            </div>
            <div className='flex items-center justify-center w-[278px] p-[26px]  rounded-[12px] bg-gradient gap-[13px]'>
              <p className='text-[12px] text-white '>Total Users in the pool :</p>
              <p className='text-[18px] text-white font-satoshi '>0</p>
              <img src='/assets/svg/users.svg' className='w-[24px]' alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
