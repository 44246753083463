import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Governance from './Pages/Governance/Governance';
import Home from './Pages/Home/Home';
// import Presale from './Pages/PreSale/PreSale';
// import PrivateSale from './Pages/PrivateSale/PrivateSale';
import Staking from './Pages/Staking/Staking';

import Layout from './Layout/Layout';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/staking' element={<Staking />} />
            {/* <Route path='/privatesale' element={<PrivateSale />} />
            <Route path='/presale' element={<Presale />} /> */}
            <Route path='/governance' element={<Governance />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
