import React, { useEffect } from "react";
import ButtonArrow from "../../assets/img/icons/btn_arrow.svg";
import { ethers } from "ethers";
import LidoAbi from "../../Contracts/LidoAbi.json";
import LidoAddress from "../../Contracts/LidoAddress.json";
import StakePrizePool from "../../Contracts/StakingContract/StakePrizePool.json";
import StakingPrizePoolAddress from "../../Contracts/StakingContract/StakePrizePoolAddress.json";
import { useState } from "react";

export default function Deposit({ activeTab }) {
  const [stEthBalance, setStEthBalance] = useState(0);
  const [fromValue, setFromValue] = useState(0);
  const setInput = (e) => {
    setFromValue(e.target.value);
  };

  const getstEthBalance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const Lido = new ethers.Contract(LidoAddress.address, LidoAbi, Signer);
      const balanceOf = await Lido.balanceOf(address);
      setStEthBalance(ethers.utils.formatEther(balanceOf));
    } catch (error) {
      console.log(error);
    }
  };
  const depositStEth = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      console.log("here")
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const Lido = new ethers.Contract(LidoAddress.address, LidoAbi, Signer);
      const stakePrizePool = new ethers.Contract(
        StakingPrizePoolAddress.address,
        StakePrizePool,
        Signer
      );
      const totalSupply = await Lido.totalSupply();
      console.log(totalSupply)
      const approve = await Lido.approve(
        StakingPrizePoolAddress.address,
        totalSupply
      );
      await approve.wait();
      await stakePrizePool.depositTo(address,  ethers.utils.parseEther(fromValue));
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(() => {
    getstEthBalance();
  }, []);
  return (
    <div>
      <div
        id="tab1"
        className={`tab-content ${activeTab === "tab1" ? "" : "hidden"}`}
      >
        <div className="py-[20px] pb-2 lg:py-[26px] max-w-[360px] mx-auto">
          <h6 className="mb-3 text-center text-[12px] text-white font-[700]">
            Note :
          </h6>

          <p className="text-center text-[12px] text-[rgba(255,255,255,0.6)] font-[400]">
            After deposit, You will receive LP tokens in your wallet address
            which represents your stETH/ETH deposits in the Brainz finance pool.
            The LP token burned when you withdraw
          </p>
        </div>

        <div className="pt-[10px] lg:pt-[37px] pb-[10px] lg:pb-[30px] px-3 lg:px-[18px] lg:bg-[rgba(255,255,255,0.05)] rounded-[18px]">
          <div className=" flex items-center pl-[24px] py-[8px] pr-[8px] border-[1px] border-[#D1D1D6] rounded-full ">
            <input
              className="w-full h-full text-[19px] text-[rgba(255,255,255,0.6)] placeholder:text-[13px] placeholder:text-[rgba(255,255,255,0.6)] focus:outline-none focus:border-none bg-transparent"
              type="text "
              onChange={(e) => setInput(e)}
              value={fromValue}
            />
            <button
              className="rounded-[20px] w-[78px] bg-themeColor text-[10px] px-[13px] py-[10px] text-[#000] font-[500] font-inter"
              onClick={(e) => setFromValue(stEthBalance)}
            >
              MAX
            </button>
          </div>

          <div className="flex gap-6 mt-4 justify-center items-center">
            <h6 className="text-center text-[11px] text-[rgba(255,255,255,0.5)] font-[500] font-satoshi">
              BALANCE ETH : <br />
              <span className="mt-2 text-[11px] text-[rgb(255,255,255)] font-[700] font-satoshi">
                0.0000 ETH
              </span>
            </h6>
            <h6 className="text-center text-[11px] text-[rgba(255,255,255,0.5)] font-[500] font-satoshi">
              BALANCE stETH : <br />
              <span className="mt-2 text-[11px] text-[rgb(255,255,255)] font-[700] font-satoshi">
                {stEthBalance}stETH
              </span>
            </h6>
          </div>
        </div>

        {/* <!-- --btn-group--  --> */}

        <div className="flex gap-[10px] w-fit mx-auto mt-3  lg:mt-5">
          <button
            className="flex items-center justify-center gap-4 py-[14px] px-[15.5px] rounded-[30px] bg-blue-300 text-[11px] sm:text-[13px] text-[#B0B0B0] font-[500] font-inter transition-all hover:bg-[#282B46] hover:text-white"
          
          >
            <img src={ButtonArrow} alt="" />
            Deposit ETH
          </button>
          <button className="flex items-center justify-center gap-4 py-[14px] px-[15.5px] rounded-[30px] bg-blue-300 text-[11px] sm:text-[13px] text-[#B0B0B0] font-[500] font-inter transition-all hover:bg-[#282B46] hover:text-white"onClick={depositStEth}>
            <img src={ButtonArrow} alt="" />
            Deposit stETH
          </button>
        </div>
      </div>
    </div>
  );
}
