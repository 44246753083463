import React from 'react';

import MyBalance from './MyBalance';
import TabSection from './TabSection';

export default function StakingCalculation() {
  return (
    <div>
      {/* <section className='mt-[125px] md:mt-[210px] lg::mt-[180px]'> */}
      <section className='mt-6 md:mt-16'>
        <div className='container'>
          <div className='grid grid-cols-1 gap-0 sm:gap-8  w-full lg:grid-cols-3'>
            {/* <!-- --left-part--  --> */}
            <div className='col-span-1 lg:col-span-2 w-full' data-aos='fade-up' data-aos-duration='1200'>
              <MyBalance />
            </div>

            {/* <!-- --right-part--  --> */}
            <div className='' data-aos='fade-up' data-aos-duration='1500'>
              <TabSection />
            </div>
          </div>
          <div className='w-full text-center my-20 lg:block'>
            <button className='w-[250px]  border border-[#15BFFD] text-[#A1E4DF] text-[17px] px-[11px] py-[8px] rounded-2xl  font-clash '>
              History
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
