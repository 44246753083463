import React, { useState } from 'react';
import { OpenedTabs_Data, Tabs_Data } from '../../_mock/dummyData';

export default function Accordian() {
  const [showValues, setShowValues] = useState(null);
  const [viewMore, setViewMore] = useState(6);

  return (
    <div>
      <div className='w-full'>
        <div className='bg-[#282B46] m-1 '>
          <div className='px-4 py-3 grid grid-cols-7 place-items-center text-center   '>
            <p className='text-[16px] w-full text-white font-[700] font-satoshi'>draws</p>
            <p className='text-[16px] w-full text-white font-[700] font-satoshi'>DATE</p>
            <p className='text-[16px] w-full text-white font-[700] font-satoshi'>REWARDS</p>
            <p className='text-[16px] w-full text-white font-[700] font-satoshi'>ADDRESS</p>
            <p className='text-[16px] w-full text-white font-[700] font-satoshi'>USER ODDS</p>
            <p className='text-[16px] w-full text-white font-[700] font-satoshi'>
              USER WIN <br />
              HISTORY
            </p>
            <p className='text-[16px] w-full text-white font-[700] font-satoshi'>
              USER TIME <br />
              IN APP
            </p>
          </div>
        </div>

        <div
          className='w-full relative max-h-[350px] overflow-auto'
          style={{
            zIndex: 999,
          }}>
          {Tabs_Data.map((item, idx) => {
            if (idx < viewMore)
              return (
                <div key={idx}>
                  <div
                    onClick={() => (idx === showValues ? setShowValues(null) : setShowValues(idx))}
                    className='grid grid-cols-7 m-1 px-4 py-3 text-center text-[14px] font-satoshi bg-[#0E1633] hover:bg-[#282B46] '>
                    <div className='flex gap-4 pl-8'>
                      <img
                        src={item.imgUrl}
                        className={`w-[20px] ${idx === showValues ? 'transform rotate-90' : ''}`}
                        alt=''
                      />
                      <p className='text-white'>#{item.id}</p>
                    </div>
                    <p className='text-white'>{item.date}</p>
                    <p className='text-white'>{item.rewards}</p>
                    <p className='text-white'>{item.address}</p>
                    <p className='text-white'>{item.odds}</p>
                    <p className='text-white'>{item.history}</p>
                    <p className='text-white'>{item.time}</p>
                  </div>
                  {idx === showValues &&
                    OpenedTabs_Data?.map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className='grid grid-cols-7 m-1 px-4 py-3 text-center text-[14px] font-satoshi  hover:bg-[#282B46] '>
                          <p className='text-white'>{item.id}</p>
                          <p className='text-white'>{item.date}</p>
                          <p className='text-white'>{item.rewards}</p>
                          <p className='text-white'>{item.address}</p>
                          <p className='text-white'>{item.odds}</p>
                          <p className='text-white'>{item.history}</p>
                          <p className='text-white'>{item.time}</p>
                        </div>
                      );
                    })}
                </div>
              );
          })}
        </div>

        <div>
          {viewMore !== 10 ? (
            <div className='w-full text-center my-20 lg:block'>
              <button
                onClick={() => setViewMore(10)}
                className='w-[250px]  border border-[#15BFFD] text-[#A1E4DF] text-[17px] px-[11px] py-[8px] rounded-2xl  font-clash '>
                View More
              </button>
            </div>
          ) : (
            <div className='w-full text-center my-20 lg:block'>
              <button
                onClick={() => setViewMore(6)}
                className='w-[250px]  border border-[#15BFFD] text-[#A1E4DF] text-[17px] px-[11px] py-[8px] rounded-2xl  font-clash '>
                View Less
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
