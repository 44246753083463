import React, { useEffect } from "react";
import SwipeArrow from "../../assets/img/icons/swipe_arrow.svg";
import PoolShare from "../../Contracts/TIcket/Ticket.json"
import PoolShareAddress from "../../Contracts/TIcket/TIcketAddress.json"
import StakePrizePool from "../../Contracts/StakingContract/StakePrizePool.json";
import StakingPrizePoolAddress from "../../Contracts/StakingContract/StakePrizePoolAddress.json";

import { useState } from "react";
import { ethers } from "ethers";

export default function Withdraw({ activeTab }) {

  const [poolShareBalance, setPoolShareBalance] = useState(0);
  const [fromValue, setFromValue] = useState(0);
  const setInput = (e) => {
    setFromValue(e.target.value);
  };

  const getPoolShareBalance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const poolShare = new ethers.Contract(PoolShareAddress.address, PoolShare, Signer);
      const balanceOf = await poolShare.balanceOf(address);
      setPoolShareBalance(ethers.utils.formatEther(balanceOf));
    } catch (error) {
      console.log(error);
    }
  };
  const withDrawStEth = async()=>{
    try{
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const stakePrizePool = new ethers.Contract(
        StakingPrizePoolAddress.address,
        StakePrizePool,
        Signer
      );
      await stakePrizePool.withdrawFrom(address,ethers.utils.parseEther(fromValue.toString()))
    }catch(error){
     console.log(error)
    }
  }
  useEffect(() => {
    getPoolShareBalance()
  }, []);
  return (
    <div
      id="tab2"
      className={`tab-content ${activeTab === "tab2" ? "" : "hidden"}`}
    >
      <div className="px-[18px] h-[163px] flex flex-col items-center justify-center mt-[26px] lg:bg-[rgba(255,255,255,0.05)] rounded-[18px]">
        <div className=" flex items-center pl-[24px] py-[8px] pr-[8px] border-[1px] border-[#D1D1D6] rounded-full ">
          <input
            className="w-full h-full text-[19px] text-[rgba(255,255,255,0.6)] placeholder:text-[13px] placeholder:text-[rgba(255,255,255,0.6)] focus:outline-none focus:border-none bg-transparent"
            type="text "
            onChange={(e) => setInput(e)}
            value={fromValue} />
            
          <button className="rounded-[20px] w-[78px] bg-themeColor text-[10px] px-[13px] py-[10px] text-[#000] font-[500] font-inter" onClick={(e)=>setFromValue(poolShareBalance)}>
            MAX
          </button>
        </div>

        <div className="flex gap-6 mt-3 justify-center items-center">
          <h6 className="text-center text-[11px] text-[rgba(255,255,255,0.5)] font-[500] font-satoshi">
            DEPOSITED BALANCE stETH : <br />
            <span className="mt-2 text-[11px] text-[rgb(255,255,255)] font-[700] font-satoshi">
              {poolShareBalance} stETH
            </span>
          </h6>
        </div>
      </div>

      {/* <!-- --btn---  --> */}

      <button className="mt-5 w-full flex items-center justify-center gap-4 py-[14px] px-[15.5px] rounded-[30px] bg-white text-[13px] text-[#000] font-[500] font-inter" onClick={withDrawStEth}>
        <img src={SwipeArrow} alt="" />
        WITHDRAW
      </button>
      {/* <!-- --text--  --> */}

      <p className="mt-5 text-center text-[10px] text-white font-[700] font-satoshi">
        Gas fees: 0
      </p>
    </div>
  );
}
