import React from 'react';
import CommingSoon from './CommingSoon';
import { Link } from 'react-router-dom';

export default function Button() {
  return (
    <div>
      <span className='text-red-500 text-xs block mb-0 text-center w-full'>Coming Soon !</span>

      <Link
        to="/staking"
        className='text-[15px] relative md:text-[20px] text-[#000] leading-none bg-themeColor py-[12px] px-[24px] md:py-[15px] md:px-[35px] hover:text-themeColor font-inter font-[500] inline-block border-[1px] border-themeColor rounded-[30px] hover:border-[1px] hover:border-themeColor hover:rounded-[30px] transition hover:bg-transparent'>
        Launch dApp
      </Link>
    </div>
  );
}
