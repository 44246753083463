import React, { useState, useEffect } from "react";

import Withdraw from "./Withdraw";
import Swap from "./Swap";
import Deposit from "./Deposit";
import PoolShare from "../../Contracts/TIcket/Ticket.json"
import PoolShareAddress from "../../Contracts/TIcket/TIcketAddress.json"
import { ethers } from "ethers";

const tabsData = [
  {
    id: 1,
    tab: "tab1",
    title: "Deposit",
  },
  {
    id: 2,
    tab: "tab2",
    title: "Withdraw",
  },
  {
    id: 3,
    tab: "tab3",
    title: "Swap",
  },
];

export default function TabSection() {
  const [activeTab, setActiveTab] = React.useState("tab1");
  const [poolShareBalance, setPoolShareBalance] = useState(0);
  const [totalPicks, setTotalPicks] = useState(0)

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const getPoolShareBalance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const poolShare = new ethers.Contract(PoolShareAddress.address, PoolShare, Signer);
      const balanceOf = await poolShare.balanceOf(address);
      setPoolShareBalance(ethers.utils.formatEther(balanceOf));
      setTotalPicks(ethers.utils.formatEther(balanceOf) / 0.1)

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPoolShareBalance()

  }, []);
  return (
    <div>
      <div className="w-full max-w-[342px] mx-auto lg:max-w-full calculation_Section mt-[44px] rounded-[13px] lg:pt-[34px] lg:px-7 lg:pb-6 md:mt-0">
        {/* <!-- top-part  --> */}
        <div className="p-8 bg-[rgba(255,255,255,0.05)] border-[1px] border-themeColor lg:border-none rounded-[18px] lg:p-0 lg:bg-transparent">
          <h6 className="text-center lg:text-left mb-[28px] text-[17px] text-[#A1E4DF] font-[500] font-clash">
            MY BALANCE
          </h6>

          <div className=" md:pr-[65px] border-[rgba(255,255,255,0.20)] md:border-r md:hidden ">
            <div className="">
              <h6 className="text-[12px] text-center text-blue-100 font-[500] font-satoshi">
                Brainz Rewards
              </h6>
              <h2 className="mt-[35px] text-[19px] text-center text-white font-[400] font-satoshi">
                0.000000 $BRAINZ
              </h2>

              <span className="text-[12px] mt-[23px] text-center block text-white font-[400] font-satoshi">
                0% Apy
              </span>
            </div>

            <div className="mb-[20px] mt-[23px] md:mb-[0] w-[209px] mx-auto text-center">
              <a
                href="#"
                className="text-center text-[11px] py-[14px] px-[20px] text-themeColor font-inter font-[500] inline-flex justify-center items-center border-[1px] border-themeColor rounded-[30px] transition hover:bg-themeColor hover:text-[#000] group"
              >
                CLAIM TOKENS
              </a>
            </div>
          </div>

          <div className="text-center pb-[35px]">
            <span className="mb-3 text-[11px] text-blue-100 font-[400] uppercase font-satoshi">
              MY DEPOSIT IN THE POOL{" "}
            </span>

            <h6 className="text-[19px] text-white font-[400] font-satoshi">
              {poolShareBalance} stETH = {totalPicks.toFixed(3)} picks
            </h6>
          </div>
          <div className="text-center mb-[21px] ">
            <span className="mb-3 text-[11px] text-blue-100 font-[400] uppercase font-satoshi">
              Weekly rewards won
            </span>

            <h6 className="text-[19px] text-white font-[400] font-satoshi">
              0.00000 stETH
            </h6>
          </div>
        </div>
        {/* <!-- --divider--  --> */}
        <div className="hidden lg:block h-[1px] w-full bg-[rgba(255,255,255,0.20)]"></div>
        {/* <!-- --bottom-part--  --> */}

        {/* <!-- ==========================---tab--==========================  --> */}

        <div className="">
          <div className="pt-4 tab-container flex gap-3 items-center justify-center">
            {tabsData.map((item) => (
              <div
                key={item?.title}
                className={`text-[16px] p-[10px] text-[rgba(255,255,255,0.5)]  ${activeTab === item.tab
                    ? " font-[500] active-tab text-themeColor border-b-[2px]"
                    : "font-[500]"
                  } tab tab-title cursor-pointer transition-all hover:text-themeColor border-themeColor`}
                onClick={() => handleTabClick(item?.tab)}
              >
                {item.title}
              </div>
            ))}
          </div>
          <Withdraw activeTab={activeTab} />
          <Swap activeTab={activeTab} />
          <Deposit activeTab={activeTab} />
        </div>
      </div>
    </div>
  );
}
