import React, { useEffect, useState } from "react";
import SwipeArrow from "../../assets/img/icons/swipe_arrow.svg";
import RouterAbi from "../../Contracts/Router.json";
import RouterAddress from "../../Contracts/Router_address.json";
import LidoAbi from "../../Contracts/LidoAbi.json";
import LidoAddress from "../../Contracts/LidoAddress.json";
import { ethers } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { mainnet } from "wagmi";
import UniswapV3 from "../../Contracts/v3abi.json";
import UniswapV3Address from "../../Contracts/v3Address.json";
import wEthAddress from "../../Contracts/WethAddress.json";
import stEthAddress from "../../Contracts/StEthAddress.json";
import UniswapV2Router from "../../Contracts/v2Router.json";
import UniswapV2Address from "../../Contracts/Router_address.json";

export default function Swap({ activeTab }) {
  const [fromValue, setFromValue] = useState(0);
  const [stEth, setStEth] = useState(0);
  const [stEthBalance, setStEthBalance] = useState(0);
  const [eth, setEth] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [isSwapped, setIsSwapped] = useState(false);

  const { setDefaultChain } = useWeb3Modal();
  setDefaultChain(mainnet);

  const setInput = (e) => {
    setFromValue(e.target.value);
  };
  const setMax = ()=>{
    if(isSwapped){
    setFromValue(stEthBalance)
    }else{
      setFromValue(ethBalance)
    }
  }

  const toggleSwap = () => {
    setIsSwapped(!isSwapped);
  };

  const getStEth = async (e) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const router = new ethers.Contract(
        RouterAddress.address,
        RouterAbi,
        Signer
      );
      const wethAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
      const path = [wethAddress, LidoAddress.address];
      const getAmountOut = await router.getAmountsOut(
        ethers.utils.parseEther(fromValue),
        path
      );
      setStEth(ethers.utils.formatEther(getAmountOut[1]));
    } catch (error) {
      console.log(error);
    }
  };
  const getEth = async (e) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const router = new ethers.Contract(
        RouterAddress.address,
        RouterAbi,
        Signer
      );
      const wethAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
      const path = [wethAddress, LidoAddress.address];
      const getAmountOut = await router.getAmountsOut(
        ethers.utils.parseEther(fromValue),
        path
      );
      setEth(ethers.utils.formatEther(getAmountOut[1]));
    } catch (error) {
      console.log(error);
    }
  };

  const getstEthBalance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const ethBalance = await provider.getBalance(address);
      setEthBalance(ethers.utils.formatEther(ethBalance));
      const Lido = new ethers.Contract(LidoAddress.address, LidoAbi, Signer);
      const balanceOf = await Lido.balanceOf(address);
      setStEthBalance(ethers.utils.formatEther(balanceOf));
    } catch (error) {
      console.log(error);
    }
  };

 

  const swapStEthToEth = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const router02 = new ethers.Contract(
        UniswapV2Address.address,
        UniswapV2Router,
        Signer
      );
      const Lido = new ethers.Contract(LidoAddress.address, LidoAbi, Signer);
      const totalSupply = await Lido.totalSupply();
      await Lido.approve(UniswapV2Address.address, totalSupply);
      const path = [LidoAddress.address, wEthAddress];
      const d = new Date();
      let time = d.getTime();
      const minute = 1000 * 60;
      const deadline = time + minute * 5;
      await router02.swapExactTokensForETHSupportingFeeOnTransferTokens(
        ethers.utils.parseEther(fromValue),
        ethers.utils.parseEther(eth.toString()),
        path,
        address,
        deadline
      );
    } catch (error) {
      console.log(error);
    }
  };
  const swapEthToStEth = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const Signer = provider.getSigner();
      const address = await Signer.getAddress();
      const Swap = new ethers.Contract(
        UniswapV3Address.address,
        UniswapV3,
        Signer
      );
      const d = new Date();
      let time = d.getTime();
      const minute = 1000 * 60;
      const deadline = time + minute * 5;
      const ethAddress = wEthAddress.address;
      const stAddress = stEthAddress.address;
      const amountIn = ethers.utils.parseEther(fromValue).toString();
      const amountOut = 0;
      const sqrtPriceLimitX96 = 0;
      const fees = 10000;
      const Params = {
        tokenIn: ethAddress,
        tokenOut: stAddress,
        fee: fees,
        recipient: address,
        deadline: deadline,
        amountIn: amountIn,
        amountOutMinimum: amountOut,
        sqrtPriceLimitX96: sqrtPriceLimitX96,
      };
      const tx = await Swap.exactInputSingle(Params, { value: amountIn });
    } catch (error) {
      console.log(error);
    }
  };
  const swap = ()=>{
    if(isSwapped){
      swapStEthToEth()
    } else if(!isSwapped){
      swapEthToStEth()
    }
  }

  useEffect(() => {
    getstEthBalance();
    getStEth();
    getEth();
  }, [fromValue]);

  return (
    <div
      id="tab3"
      className={`tab-content ${activeTab === "tab3" ? "" : "hidden"}`}
    >
      <div className="py-4 mt-3 lg:mt-0 lg:py-[26px] flex-col md:flex-row flex gap-5 justify-between items-center">
        <div className=" bg-white rounded-[7px] py-[7px] px-[12px]">
          <p className="text-[8px] text-[#757575] font-[400] font-inter">
            From
          </p>
          <div className="flex justify-between">
            <input
              className="text-[12px] text-[#212121] font-[400] font-inter border-0 outline-none w-full"
              onChange={(e) => setInput(e)}
              value={fromValue}
            />
            <h6 className="text-[12px] text-[#000] font-[600] font-inter">
              {isSwapped ? "stEth" : "Eth"}
            </h6>
          </div>
        </div>
        <div
          className="shrink-0 w-[33px] h-[33px] rounded-full flex justify-center items-center bg-white"
          onClick={toggleSwap}
        >
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3422 8.59325L10.1751 7.42618L12.2682 5.33305L1.3965 5.33305L1.3965 3.68232L12.2682 3.68232L10.1751 1.59001L11.3422 0.422119L15.4277 4.50768L11.3422 8.59325ZM4.6567 16.8469L0.571137 12.7613L4.6567 8.67578L5.82377 9.84285L3.73064 11.9368L14.6024 11.936L14.6024 13.5867L3.73064 13.5867L5.82377 15.6798L4.6567 16.8469V16.8469Z"
              fill="#1573FE"
            />
          </svg>
        </div>
        <div className="bg-white rounded-[7px] py-[7px] px-[12px]">
          <p className="text-[8px] text-[#757575] font-[400] font-inter">To</p>
          <div className="flex justify-between">
            <input
              className="text-[12px] text-[#212121] font-[400] font-inter border-0 outline-none w-full"
              value={isSwapped ? eth : stEth}
            />
            <h6 className="text-[12px] text-[#000] font-[600] font-inter">
              {isSwapped ? "Eth" : "stEth"}
            </h6>
          </div>
        </div>
      </div>

      <div className="px-[18px] h-[163px] flex flex-col items-center justify-center mt-[26px] lg:bg-[rgba(255,255,255,0.05)] rounded-[18px]">
        <div className=" flex items-center pl-[24px] py-[8px] pr-[8px] border-[1px] border-[#D1D1D6] rounded-full ">
          <input
            className="w-full h-full text-[19px] text-[rgba(255,255,255,0.6)] placeholder:text-[13px] placeholder:text-[rgba(255,255,255,0.6)] focus:outline-none focus:border-none bg-transparent"
            type="text "
            placeholder=  {isSwapped ? "stEth" : "Eth"}
            value={isSwapped ?stEthBalance:ethBalance}
          />
          <button className="rounded-[20px] w-[78px] bg-themeColor text-[10px] px-[13px] py-[10px] text-[#000] font-[500] font-inter" onClick={setMax}>
            MAX
          </button>
        </div>

        <div className="flex gap-6 mt-3 justify-center items-center">
          <h6 className="text-center text-[11px] text-[rgba(255,255,255,0.5)] font-[500] font-satoshi">
            BALANCE ETH : <br />
            <span className="mt-2 text-[11px] text-[rgb(255,255,255)] font-[700] font-satoshi">
              {ethBalance} ETH
            </span>
          </h6>
          <h6 className="text-center text-[11px] text-[rgba(255,255,255,0.5)] font-[500] font-satoshi">
            BALANCE stETH : <br />
            <span className="mt-2 text-[11px] text-[rgb(255,255,255)] font-[700] font-satoshi">
              {stEthBalance}stETH
            </span>
          </h6>
        </div>
      </div>

      {/* <!-- --btn---  --> */}

      <button
        className="mt-2 lg:mt-5 w-full flex items-center justify-center gap-4 py-[14px] px-[15.5px] rounded-[30px] bg-white text-[13px] text-[#000] font-[500] font-inter hover:bg-themeColor transition-all"
        onClick={swap}
      >
        <img src={SwipeArrow} alt="" />
        SWAP
      </button>
      {/* <!-- --text--  --> */}

      <p className="mt-5 text-center text-[10px] text-white font-[700] font-satoshi">
        Gas fees: 0
      </p>
    </div>
  );
}
