import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import footerBg from '../assets/img/bg/Footer_bg.png';
import footerRightShape from '../assets/img/bg/footer-right_shape.png';
import footerLeftShape from '../assets/img/bg/footer_left_shape.png';
import logo from '../assets/img/logos/logo.svg';

const pathDetails = [
  {
    id: 1,
    title: 'Staking',
    // path: '/staking',
  },
  // {
  //   id: 2,
  //   title: 'Private sale',
  //   // path: '/privatesale',
  // },
  // {
  //   id: 3,
  //   title: 'Presale',
  //   // path: '/presale',
  // },
  {
    id: 4,
    title: 'Governance',
    // path: '/governance',
  },
  {
    id: 5,
    title: 'Whitepaper',
    path: 'https://brainzfinance.gitbook.io/whitepaper/',
  },
  {
    id: 6,
    title: 'Contact Us',
    // path: 'https://brainzfinance.gitbook.io/whitepaper/',
  },
];

const socialMediaLinks = [
  {
    id: 1,
    img: '/assets/svg/twitter.svg',
    path: 'https://www.twitter.com/brainzfinance',
  },
  {
    id: 2,
    img: '/assets/svg/telegram.svg',
    path: 'https://www.t.me/brainzfinance',
  },
  {
    id: 3,
    img: '/assets/svg/instagram.svg',
    path: '/',
  },
  {
    id: 4,
    img: '/assets/svg/medium.svg',
    path: '/',
  },
  {
    id: 5,
    img: '/assets/svg/github.svg',
    path: 'https://github.com/brainzfinance',
  },
  {
    id: 6,
    img: '/assets/svg/raddit.svg',
    path: '/',
  },
];

const Footer = () => {
  const location = useLocation();

  return (
    <footer className='relative z-10 md:bg-[#111131] bg-transparent'>
      {/* Shape */}
      <div className='hidden md:block'>
        <img className='absolute bottom-0 left-0 -z-10' src={footerLeftShape} alt='' />
        <img className='absolute bottom-0 right-0 -z-10' src={footerRightShape} alt='' />
      </div>
      <div className='hidden md:block'>
        <img src={footerBg} alt='' className='w-full' />
      </div>
      <div className='container'>
        <div className='py-[56px] lg:py-[50px] flex justify-center lg:justify-between items-center'>
          {/* Nav links */}

          <Link to='/' className=''>
            <img src={logo} alt='' />
          </Link>

          <ul className='gap-9 hidden lg:flex'>
            {pathDetails.map((item, idx) => {
              return (
                <li key={item.id}>
                  <Link
                    target={item.id === 5 && '_blank'}
                    to={item.path}
                    className={`text-[17px] text-[#fff] font-[700] font-satoshi transition hover:text-[#15BFFD] ${location.pathname === item.path &&
                      'text-[#15BFFD] px-[10px] py-[10px]  bg-[#141735] rounded-[32px]'
                      }`}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='  block lg:flex gap-14 md:gap-4 justify-between items-center'>
          <div className='flex gap-2 justify-center'>
            {socialMediaLinks.map((item) => (
              <Link
                key={item.id}
                to='https://www.t.me/brainzfinance'
                target='_blank'
                className='flex justify-center items-center p-3 rounded-full border border-[rgba(255,255,255,0.26)]'
                rel='noreferrer'>
                <img src={item.img} alt='' className='w-[17px] h-[17px]' />
              </Link>
            ))}
          </div>

          <div className='py-[56px] text-center lg:text-left lg:max-w-[632px] w-full flex flex-col md:flex-row justify-between text-[17px] font-bold font-satoshi'>
            <p className=' text-[#fff] font-[400]'>
              Communications and Partnership <br />{' '}
              <span className='text-[#A1E4DF]'>
                <a href='mailto:hi@brainz.finance'>Hi@brainz.finance</a>
              </span>
            </p>
            <p className='pt-[56px] md:pt-0 text-[#fff] font-[400]'>
              Troubleshooting
              <br />{' '}
              <span className='text-[#A1E4DF]'>
                <a href='mailto:support@brainz.finance'>Support@brainz.finance</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
