import React from 'react';
import WhiteSmallStar from '../../assets/img/icons/whiite_small_star.svg';

import YellowBigStar from '../../assets/img/icons/yellow_big_star.svg';
import YellowMediumStar from '../../assets/img/icons/yellow_medium_star.svg';
import LinkButtonRightArrow from '../../assets/img/icons/link_btn_right_arrow.svg';
import { Link } from 'react-router-dom';
import CommingSoon from '../CommingSoon';
import Button from '../Button';

export default function HeroSectoin() {
  return (
    <section class='bg-transparent mt-16 md:mt-28 lg:mt-[150px] pt-28 md:pt-[150px] pb-[50px] md:pb-[200px] relative'>
      <div className='hidden md:block'>
        <img
          className='absolute top-[10%] left-[90%]'
          data-aos='fade-left'
          data-aos-duration='1300'
          src={YellowBigStar}
          alt=''
        />
        <img
          className='absolute top-[10%] left-[60%]'
          data-aos='fade-in'
          data-aos-duration='1300'
          src={YellowMediumStar}
          alt=''
        />
        <img
          className='absolute top-[15%] left-[15%]'
          data-aos='fade-right'
          data-aos-duration='1300'
          src={YellowMediumStar}
          alt=''
        />

        <img
          className='absolute top-[60%] left-[5%]'
          data-aos='fade-right'
          data-aos-duration='1300'
          src={WhiteSmallStar}
          alt=''
        />
        <img
          className='absolute bottom-[10%] left-[50%]'
          data-aos='fade-up'
          data-aos-duration='1300'
          src={YellowMediumStar}
          alt=''
        />
      </div>
      <div className='block md:hidden'>
        <img className='absolute -z-10 top-7 right-10' src={YellowBigStar} alt='' />
        <img className='absolute -z-10 top-20 left-0' src={YellowMediumStar} alt='' />
      </div>
      <div className='container'>
        <div className='hero_content z-20' data-aos='fade-up' data-aos-duration='1000'>
          <div className='hero-text max-w-[786px] mx-auto'>
            <h1 className='text-[32px] md:text-[48px] text-[#fff] text-center font-[600] font-clash tracking-[0.96px]'>
              <span className='text-themeColor'>The first meme coin</span> to harness the power of Liquidity Staking
              Derivative Protocol
            </h1>
            <p className='text-[20px] md:text-[26px] py-[35px] text-[#ADAEB9] text-center font-[400] font-inter tracking-[0.26px]'>
              Accelerate Your Financial Journey: Staking Success Awaits with Brainz Finance!
            </p>
          </div>
          {/* --hero-btns--  */}
          <div className='flex gap-10 justify-center'>
            {/* --solid-btn--  */}

            <Button />
            {/* --link-btn--  */}
            <a
              href='https://brainzfinance.gitbook.io/whitepaper/'
              className='flex gap-3 items-center text-[15px] md:text-[20px] leading-none py-[12px] md:py-[15px] text-themeColor font-inter font-[500] transition hover:text-white'>
              Learn more
              <img src={LinkButtonRightArrow} alt='' />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
